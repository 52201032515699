import React, { act, useEffect, useState } from "react";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, onValue } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

export default function App() {
  let [activeUser, setActiveUser] = useState(null);
  let [activeUserName, setActiveUserName] = useState([]);
  const firebaseConfig = {
    apiKey: "AIzaSyCqoQXWG11TJ2ZlWuHCise7tZ2SkoJr-kY",
    authDomain: "personal-2e64f.firebaseapp.com",
    databaseURL: "https://personal-2e64f-default-rtdb.firebaseio.com",
    projectId: "personal-2e64f",
    storageBucket: "personal-2e64f.firebasestorage.app",
    messagingSenderId: "258888089138",
    appId: "1:258888089138:web:58d8785a628c6acf5c062b",
    measurementId: "G-261T35PTXN",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const db = getDatabase(app);

  let lastUpdated = Date.now();
  try {
    const activeSession = ref(db, "activeSession/");
    onValue(activeSession, (snapshot) => {
      const data = snapshot.val();
      if (Date.now() - lastUpdated > 900) {

        setActiveUser(data);
        setActiveUserName(Object.keys(data));
      }
    });
  } catch (error) {
    console.log("error hai");
  }

  return (
    <React.Fragment>
      <div className="row">
        {activeUserName.map((item) => {
          return (
            <div className="col-6 col-md-2 border" style={{ padding: 20,height:300 }} address={item}>
              <div className="heading text-center">
                <h6>{item}</h6>
                <span style={{ fontSize: 14 }}>Last updated at {new Date(activeUser[item].lastupdated).toString()}</span>
              </div>
              <div>
                <div>
                  <h6>Bal : {activeUser[item].bal}</h6>
                </div>
                <div>
                  <h6>Exp : {activeUser[item].exp}</h6>
                </div>
              </div>
              <div>
                <h6>Url :</h6>
                <span style={{wordWrap:'break-word'}}>{activeUser[item].url}</span>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}
